import { IonContent, IonPage, IonList, IonItem, IonLabel, IonText, IonListHeader } from '@ionic/react';
import { useProjects } from '../lib/useProjects';
import { useHistory } from 'react-router-dom';
import AfnHeader from '../components/AfnHeader';

const ProjectList: React.FC = () => {
    const projects = useProjects();
    const history = useHistory();

    return (
        <IonPage>
            <AfnHeader showBackButton={false}>Projects</AfnHeader>
            <IonContent>
                <IonList>
                    <IonListHeader>My Projects</IonListHeader>
                    {projects.map((project, index) => (
                        <IonItem
                            key={index}
                            detail={true}
                            button={true}
                            onClick={() => history.push({ pathname: '/projects/' + project.id })}
                        >
                            <IonLabel className="ion-text-wrap">
                                <IonText color="primary">
                                    <h1>{project.name}</h1>
                                </IonText>
                                <IonText>
                                    <p>{project.locations.join(', ')}</p>
                                </IonText>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default ProjectList;
