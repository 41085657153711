//import dayjs from 'dayjs';

export function titleCase(s: string) {
    s = s[0].toUpperCase() + s.substr(1);
    s = s.replace(/(\s\w)/g, (r) => r.toUpperCase());
    return s;
}

export function asNumber(s: string, defaultValue: number | undefined = undefined) {
    const n = Number(s);
    if (isNaN(n)) return defaultValue;
    return n;
}

export function formatNumber(n: number, decimalPlaces = 1, unit?: string) {
    return n.toLocaleString(undefined, { maximumFractionDigits: decimalPlaces }) + (unit ? ` ${unit}` : '');
}

//export function formatDate(s: string, format = 'ddd, D MMM YYYY h:mma ([GMT]Z)') {
//   return dayjs(s).format(format);
//}
