import { Filesystem } from '@capacitor/filesystem';

export type AttachmentCacheEntry = {
    attachmentId: string;
    localfilepath: string;
};

export function photoFormatToMimetype(format: string) {
    // jpeg, png, gif
    switch (format) {
        case 'jpeg':
        case 'jpg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'gif':
            return 'image/gif';
        default:
            return 'image/jpeg';
    }
}

export function base64ToDataUrl(base64Data: string, mimeType: string): string {
    return `data:${mimeType};base64,${base64Data}`;
}

export function parseDataurl(dataurl: string): { base64: string; mimetype: string } {
    const match = dataurl.match(new RegExp('data:([^;]+);base64,(.*)'));

    if (match?.length == 3) {
        return {
            mimetype: match[1],
            base64: match[2],
        };
    } else {
        throw new Error("Couldn't parse dataurl");
    }
}

export async function blobToBase64(blob: Blob) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            const dataUrl = reader.result;
            if (typeof dataUrl == 'string') {
                resolve(dataUrl.split(',')[1]);
            } else {
                reject(new Error('unexpected blob decode'));
            }
        };
        reader.readAsDataURL(blob);
    });
}

export async function base64ToBlob(base64Data: string, mimeType: string) {
    const dataurl = base64ToDataUrl(base64Data, mimeType);
    return await (await fetch(dataurl)).blob();
}

export async function readFileToBlob(filepath: string, mimeType: string): Promise<Blob> {
    const res = await Filesystem.readFile({
        path: filepath,
    });

    return base64ToBlob(res.data, mimeType);
}

export async function readFileToDataUrl(filepath: string, mimeType: string): Promise<string> {
    const res = await Filesystem.readFile({
        path: filepath,
    });

    return base64ToDataUrl(res.data, mimeType);
}

export async function thumbNailUrlFromImageData(
    imageurl: string,
    width: number,
    height: number
): Promise<string | undefined> {
    const canvasEl = document.createElement('canvas');

    const loadedImageEl = await new Promise<HTMLImageElement>((resolve) => {
        const imageEl = document.createElement('img');
        imageEl.src = imageurl;
        imageEl.onload = () => resolve(imageEl);
    });

    //scaling
    canvasEl.width =
        loadedImageEl.naturalWidth >= loadedImageEl.naturalHeight
            ? width
            : Math.floor(loadedImageEl.naturalWidth / (loadedImageEl.naturalHeight / height));
    canvasEl.height =
        loadedImageEl.naturalHeight >= loadedImageEl.naturalWidth
            ? height
            : Math.floor(loadedImageEl.naturalHeight / (loadedImageEl.naturalWidth / width));

    console.log(
        'thumbnail scale from ',
        loadedImageEl.naturalWidth,
        loadedImageEl.naturalHeight,
        ' to ',
        canvasEl.width,
        canvasEl.height
    );
    const ctx = canvasEl.getContext('2d');
    if (ctx) {
        ctx.drawImage(loadedImageEl, 0, 0, canvasEl.width, canvasEl.height);
        return canvasEl.toDataURL('image/jpeg', 0.8);
    }
    return undefined;
}
