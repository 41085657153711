import { Camera, CameraDirection, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { IonButton, IonContent, IonItem, IonLabel, IonPage, IonThumbnail } from '@ionic/react';
import { useEffect, useState } from 'react';
import AfnHeader from '../../components/AfnHeader';
import Markdown from '../../components/Markdown';
import { Attachment, MeasurementAttachmentDetail } from '../../lib/ApiModel';
import { CancellablePromise } from '../../lib/helper/PromiseHelper';
import ProjectAssetSiteData from '../../lib/ProjectAssetData';

type SitePhotoCollectionProps = {
    siteData: ProjectAssetSiteData;
};

function AttachmentThumbnail({
    attachment,
    siteData,
    slot,
}: {
    slot?: string;
    attachment: Attachment;
    siteData: ProjectAssetSiteData;
}) {
    const [dataurl, setdataurl] = useState<string | undefined>();
    useEffect(() => {
        const prom = new CancellablePromise(() => siteData.getAttachmentThumbnailDataUrl(attachment, 100, 100));
        prom.result.then((url) => {
            setdataurl(url);
        });

        return () => prom.cancel();
    }, [attachment, siteData]);

    return (
        <IonThumbnail title={dataurl} slot={slot}>
            <img src={dataurl} />
        </IonThumbnail>
    );
}

async function takePhoto(): Promise<Photo> {
    return await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        direction: CameraDirection.Rear,
        webUseInput: true,
        width: 2048,
        quality: 95,
    });
}

function SitePhotoItem({
    sitePhoto,
    siteData,
}: {
    sitePhoto: MeasurementAttachmentDetail;
    siteData: ProjectAssetSiteData;
}) {
    const existingPhoto = siteData.attachmentForPhoto(sitePhoto.fileName);

    const capture = async () => {
        const photo = await takePhoto();
        if (photo.dataUrl) {
            await siteData.createSiteAttachment(photo.dataUrl, sitePhoto.fileName, 'SiteDataPhoto');
        }
    };

    const replace = async (existingAttachmentId: string) => {
        const photo = await takePhoto();
        if (photo.dataUrl) {
            await siteData.updateSiteAttachment(photo.dataUrl, sitePhoto.fileName, existingAttachmentId);
        }
    };

    const remove = async (existingAttachmentId: string) => {
        await siteData.deleteSiteAttachment(existingAttachmentId);
    };

    return (
        <IonItem>
            {existingPhoto && (
                <>
                    <AttachmentThumbnail attachment={existingPhoto} siteData={siteData} slot="end" />
                </>
            )}
            <IonLabel className="ion-text-wrap">
                <h2>
                    {sitePhoto.name} {sitePhoto.mandatory && <small style={{ marginLeft: '0.5rem' }}>Required</small>}
                </h2>
                <Markdown source={sitePhoto.description} />
                {existingPhoto && (
                    <>
                        <IonButton onClick={() => replace(existingPhoto.id)}>Replace</IonButton>
                        <IonButton color="danger" onClick={() => remove(existingPhoto.id)}>
                            Remove
                        </IonButton>
                    </>
                )}
                {!existingPhoto && (
                    <>
                        <IonButton onClick={capture}>Capture</IonButton>
                    </>
                )}
            </IonLabel>
        </IonItem>
    );
}

const SitePhotoCollection: React.FC<SitePhotoCollectionProps> = ({ siteData }) => {
    const sitePhotos = siteData.sitePhotos;

    return (
        <IonPage>
            <AfnHeader showBackButton={true}>Site Photos</AfnHeader>
            <IonContent>
                {sitePhotos.map((sp) => (
                    <SitePhotoItem key={sp.fileName} siteData={siteData} sitePhoto={sp} />
                ))}
            </IonContent>
        </IonPage>
    );
};

export default SitePhotoCollection;
